import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';

// Http
import promise from 'redux-promise';
import thunk from 'redux-thunk';

// Firebase
import * as firebase from 'firebase';

// Reducers
import reducers from './reducers/';

// Containers
import Preloader from './components/preloader';

// FIREBASE DEV
// Be carefull with database.rules when deploying

const config = {
  apiKey: 'AIzaSyBKatjyx1QCdDTLQn_SV71icmfrIDYAFP0',
  authDomain: 'tatest-1e98b.firebaseapp.com',
  databaseURL: 'https://tatest-1e98b.firebaseio.com',
  projectId: 'tatest-1e98b',
  storageBucket: 'tatest-1e98b.appspot.com',
  messagingSenderId: '406519314968',
};

// FIREBASE PROD
// Be carefull with database.rules when deploying
/*
const config = {
  apiKey: 'AIzaSyCHUNcAfE3OOTRfLYCsagZXSMD6hqztHKY',
  authDomain: 'taapp-4cad0.firebaseapp.com',
  databaseURL: 'https://taapp-4cad0.firebaseio.com',
  projectId: 'taapp-4cad0',
  storageBucket: 'taapp-4cad0.appspot.com',
  messagingSenderId: '535262134115',
};
*/
firebase.initializeApp(config);

// Create store
const store = createStore(reducers, applyMiddleware(promise, thunk));

class App extends Component {
  componentDidMount() {
    console.log('v.5.2');
    if (config.projectId === 'tatest-1e98b') {
      console.log('DEVELOPMENT VERSION');
    }
  }

  render() {
    return (
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Provider store={store}>
          <Preloader />
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
